
import { defineComponent, onMounted, watch } from "vue";
import { formatDate } from "@/utils/dateHelpers";
import * as echarts from "echarts";
import { createRandomPrefixedId } from "@/utils/globalHelpers";

export default defineComponent({
  name: "DemandChart",
  props: {
    timeIntervals: {
      required: true,
      type: Array as () => Date[],
      default: () => []
    },
    demandArray: {
      required: true,
      type: Array as () => number[][],
      default: () => []
    }
  },

  setup(props) {
    const chartId = createRandomPrefixedId("demandChart");

    // // Create stacked list of multiple demand arrays, earch with its individual color
    // // not usefull for planning the demand of one work area.
    // // maybe for multiple Workareas, but then the name would be "workarea" instad of "demand"
    // const createDemandSeries = (demandArrayList: number[][]): any => {
    //   const demandSeries = [];
    //   for (let i = 0; i < demandArrayList.length; i++) {
    //     const data = {
    //       name: `Demand${i}`,
    //       type: "line",
    //       step: "start",
    //       stack: "total",
    //       areaStyle: {},
    //       data: demandArrayList[i]
    //     };
    //     demandSeries.push(data);
    //   }
    //   return demandSeries;
    // };

    const drawChart = () => {
      const chartOptions: echarts.EChartsOption = {
        tooltip: {
          // Option config. Can be overwrited by series or data
          trigger: "axis",
          textStyle: {
            fontFamily: "Verdana, sans-serif",
            fontWeight: "bolder"
          },
          confine: true,
          className: "z-40"
        },
        xAxis: {
          data: props.timeIntervals.map(interval => formatDate(interval, "HH:mm")),
          type: "category",
          axisLabel: { fontSize: 14 },
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: { type: "value", minInterval: 1, splitNumber: 3, axisLabel: { fontSize: 14 } },
        series: {
          name: `Demand`,
          type: "line",
          step: "start",
          areaStyle: {},
          data: props.demandArray
        },
        grid: {
          left: 20,
          top: 20,
          right: 20,
          bottom: 20
        }
      };
      return chartOptions;
    };

    // initialize echarts instance with prepared DOM
    onMounted(() => {
      const chartElement = document.getElementById(chartId);

      if (chartElement) {
        // initialize chart object
        // eslint-disable-next-line
        const demandChart: any = echarts.init(chartElement);
        // draw initial chart
        demandChart.setOption(drawChart());
        // responsive chart
        window.addEventListener("resize", demandChart.resize);
        // watch for array updates
        watch(
          () => props.demandArray,
          () => {
            // update chart
            demandChart.setOption(drawChart());
          }
        );
      }
    });

    return { chartId };
  }
});
