import gql from "graphql-tag";

import { demandTemplateBaseFragment } from "../fragments/demandTemplateBase";

// get all users with role employee
export const DEMAND_TEMPLATES = gql`
  query demandTemplates(
    $ids: [uuid!]
    $workAreaId: uuid
    $scheduleId: uuid
    $generalTemplate: Boolean!
    $tenantId: uuid!
  ) {
    demand_template(
      where: {
        id: { _in: $ids }
        work_area_id: { _eq: $workAreaId }
        schedule_id: { _is_null: $generalTemplate, _eq: $scheduleId }
        tenant_id: { _eq: $tenantId }
      }
    ) {
      ...DemandTemplateBase
    }
  }
  ${demandTemplateBaseFragment.demandTemplateBase}
`;
