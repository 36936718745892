import gql from "graphql-tag";

// delete employee
export const DELETE_DEMAND_PARENT = gql`
  mutation deleteDemandParent($demandParentId: uuid!, $tenantId: uuid!) {
    delete_demand_parent(where: { id: { _eq: $demandParentId }, tenant_id: { _eq: $tenantId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
