import gql from "graphql-tag";
import { demandParentBaseFragment } from "../fragments/demandParentBase";

// create new employee with contract
export const CREATE_DEMAND_PARENT = gql`
  mutation createDemandParent(
    $startTime: time!
    $endTime: time!
    $amount: Int!
    $workAreaId: uuid!
    $demandTemplateId: uuid!
    $tenantId: uuid!
  ) {
    insert_demand_parent_one(
      object: {
        start_time: $startTime
        end_time: $endTime
        amount: $amount
        work_area_id: $workAreaId
        demand_template_id: $demandTemplateId
        tenant_id: $tenantId
      }
    ) {
      ...DemandParentBase
    }
  }

  ${demandParentBaseFragment.demandParentBase}
`;
