import gql from "graphql-tag";

export const demandParentBaseFragment = {
  demandParentBase: gql`
    fragment DemandParentBase on demand_parent {
      id
      start_time
      end_time
      amount
      work_area_id
      demand_template_id
    }
  `
};
