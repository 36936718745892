<template>
  <div @click="onClickDelete">
    <slot></slot>
    <DeleteConfirmationModal
      :visible="deleteConfirmationVisible"
      :header="header"
      :message="message"
      :loading="loading"
      @confirm="onConfirmDelete"
      @close="onCancelDelete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "vue";
// components
import DeleteConfirmationModal from "@/components/modals/DeleteConfirmationModal.vue";
// store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
// other
import { DemandTemplate } from "@/graphql/types";

export default defineComponent({
  name: "DemandTemplateDeleteButton",
  components: {
    DeleteConfirmationModal
  },
  props: {
    demandTemplate: { type: Object as PropType<DemandTemplate>, required: true }
  },
  emits: {
    deleted: null,
    canceled: null
  },
  setup(props, context) {
    // store
    const { onDeleteDemandTemplate, onDeleteDemandTemplateSuccess, loading } = useDemandTemplateStore({
      allDemandTemplates: true
    });

    /**
     * Confirmation dialoge
     */

    // confirmation diaglog visibility
    const deleteConfirmationVisible = ref(false);
    const onClickDelete = () => {
      deleteConfirmationVisible.value = true;
    };

    // confirmation dialog message
    const header = computed(() => `Delete demand template`);
    const message = `Are you really sure you want to delete this demand template? This action can't be undone.`;

    /**
     * Confirmation dialoge actions
     */

    const onConfirmDelete = () => {
      onDeleteDemandTemplate(props.demandTemplate.id);
    };

    const onCancelDelete = () => {
      deleteConfirmationVisible.value = false;
      context.emit("canceled");
    };

    onDeleteDemandTemplateSuccess(() => {
      deleteConfirmationVisible.value = false;
      context.emit("deleted");
      console.info("Demand template deleted");
    });

    return {
      header,
      message,
      onClickDelete,
      deleteConfirmationVisible,
      loading,
      onConfirmDelete,
      onCancelDelete
    };
  }
});
</script>
