import gql from "graphql-tag";

import { demandTemplateBaseFragment } from "../fragments/demandTemplateBase";

// get employee by id
export const DEMAND_TEMPLATE = gql`
  query demandTemplate($demandTemplateId: uuid!, $tenantId: uuid!) {
    demand_template(where: { id: { _eq: $demandTemplateId }, tenant_id: { _eq: $tenantId } }) {
      ...DemandTemplateBase
    }
  }
  ${demandTemplateBaseFragment.demandTemplateBase}
`;
