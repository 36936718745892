import gql from "graphql-tag";

export const recurrencePatternBaseFragment = {
  recurrencePatternBase: gql`
    fragment RecurrencePatternBase on recurrence_pattern {
      id
      freq
      byweekday
      interval
      bysetpos
      bymonth
      bydates
      dtstart
      demand_template_id
    }
  `
};
