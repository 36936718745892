import { reactive, ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import useTimeIntervals from "@/utils/useTimeIntervals";
import {
  parseTimeString,
  getDifference,
  getTimeStringFromDate,
  removeSecondsFromTimeString
} from "@/utils/dateHelpers";
import { DemandParent, DemandParentBaseFragment, Demand } from "@/graphql/types";

export interface DemandFormInput {
  id: string;
  startTime: string;
  endTime: string;
  amount: number;
  hasError: boolean;
}

export interface DemandArrayObject {
  demandStartIndex: number;
  demandEndIndex: number;
  demandAmount: number;
  timeIntervalCount: number;
}

export function createDemandFormInputFromDemandParent(
  demandParent: DemandParent | DemandParentBaseFragment
): DemandFormInput {
  return {
    id: demandParent.id,
    startTime: removeSecondsFromTimeString(demandParent.start_time),
    endTime: removeSecondsFromTimeString(demandParent.end_time),
    amount: demandParent.amount,
    hasError: false
  };
}

export function createDemandFormInputFromDemand(demand: Demand) {
  return {
    id: demand.id,
    startTime: getTimeStringFromDate(demand.start_datetime),
    endTime: getTimeStringFromDate(demand.end_datetime),
    amount: demand.amount,
    hasError: false
  };
}

/**
 * Sort demand arrays based on 3 criteria:
 * prio1: earliest start
 * prio2: latest end
 * prio3: amount the smalest
 * Logic: The first array in the list will get overwritten by the successors
 * Sort function source: https://stackoverflow.com/questions/6913512/how-to-sort-an-array-of-objects-by-multiple-fields
 */
export function sortDemandArrayObjects(objectList: DemandArrayObject[]) {
  const sortedList = objectList.sort(
    (a, b) =>
      a.demandStartIndex - b.demandStartIndex || b.demandEndIndex - a.demandEndIndex || a.demandAmount - b.demandAmount
  );
  return sortedList;
}

/**
 * Create object that is the basis for creating a demand array
 */
export function createDemandArrayObject(options: {
  demandStartTime: string;
  demandEndTime: string;
  demandAmount: number;
  dayStartTime: string;
  timeIntervalSize: number;
  timeIntervalCount: number;
}) {
  // get variables
  const { demandStartTime, demandEndTime, demandAmount, dayStartTime, timeIntervalSize, timeIntervalCount } = options;
  // calculate start and end index of demand in total day
  const minutesBeforeDemand = getDifference(parseTimeString(dayStartTime), parseTimeString(demandStartTime), "minutes");
  const minutesDuringDemand = getDifference(
    parseTimeString(demandStartTime),
    parseTimeString(demandEndTime),
    "minutes"
  );

  // range in index where the demand is relevant
  const demandStartIndex = minutesBeforeDemand / timeIntervalSize;
  const demandEndIndex = demandStartIndex + minutesDuringDemand / timeIntervalSize;

  const demandArrayObject = {
    demandStartIndex: demandStartIndex,
    demandEndIndex: demandEndIndex,
    demandAmount: demandAmount,
    timeIntervalCount: timeIntervalCount
  };

  return demandArrayObject;
}

/**
 * Create demand array from a demand array object
 * If an array is given, the values of the array are overwritten
 */
export function createDemandArray(demandArrayObject: DemandArrayObject, baseArray: number[] = []) {
  // initialize array
  let demandArray: number[] = [];
  if (baseArray.length !== demandArrayObject.timeIntervalCount) {
    // initialize new demand array with 0
    demandArray = new Array(demandArrayObject.timeIntervalCount).fill(0);
  } else demandArray = baseArray;

  // fill intervals of demand array
  demandArray = demandArray.fill(
    demandArrayObject.demandAmount,
    demandArrayObject.demandStartIndex,
    demandArrayObject.demandEndIndex
  );
  return demandArray;
}

/**
 * Create accumulated demand array of multiple demand arrays
 */
export function createAccumulatedDemandArray(demandObjectList: DemandArrayObject[]) {
  // sort objects
  const sortedList = sortDemandArrayObjects(demandObjectList);
  // create demand array
  // logic: overwrite the first array with the successor values (see sortDemandArrayObjects)
  const demandArray = sortedList.reduce(
    (accumulator, demandArrayObject) => createDemandArray(demandArrayObject, accumulator),
    [] as number[]
  );

  return demandArray;
}

// demand hook
export default function useDemand() {
  // get global start and end time
  const { startTime, endTime, timeIntervalSize, timeIntervals } = useTimeIntervals();

  const createDemandFormInput = (
    options: { id?: string; startTime?: string; endTime?: string; amount?: number } = {}
  ) => {
    // initialize demand object
    const demand: DemandFormInput = reactive({
      // random id as default
      id: options.id ? options.id : uuidv4(),
      // global start and end time as default
      startTime: options.startTime ? options.startTime : startTime.value,
      endTime: options.endTime ? options.endTime : endTime.value,
      // 1 as default
      amount: options.amount ? options.amount : 1,
      hasError: false
    });
    return demand;
  };

  // convert demand form input to demand array object
  const createDemandArrayObjectFromDemandFormInput = (demandFormInput: DemandFormInput) => {
    const demandArrayObject = createDemandArrayObject({
      demandStartTime: demandFormInput.startTime,
      demandEndTime: demandFormInput.endTime,
      demandAmount: demandFormInput.amount,
      dayStartTime: startTime.value,
      timeIntervalSize: timeIntervalSize.value,
      timeIntervalCount: timeIntervals.value.length
    });
    return demandArrayObject;
  };

  // create demand array form list of demand form inptus
  const createDemandArrayFromDemandFormInputList = (demandList: DemandFormInput[]) => {
    // remove all objects that have errors
    const demandListFiltered = demandList.filter(demand => demand.hasError === false);
    // craete demand array objects
    const objectList = demandListFiltered.map(demand => createDemandArrayObjectFromDemandFormInput(demand));
    // accumulated demand array
    const demandArray = createAccumulatedDemandArray(objectList);
    return demandArray;
  };

  return { createDemandFormInput, createDemandArrayFromDemandFormInputList, timeIntervals };
}
