import { computed } from "vue";
// Graphql
import useAuth from "@/api/auth/useAuth";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { DemandTemplateQuery, DemandTemplatesQuery } from "@/graphql/types";
// demand template
import { DEMAND_TEMPLATES } from "@/graphql/demandTemplates/queries/demandTemplates";
import { DEMAND_TEMPLATE } from "@/graphql/demandTemplates/queries/demandTemplate";
import { CREATE_DEMAND_TEMPLATE } from "@/graphql/demandTemplates/mutations/createDemandTemplate";
import { DELETE_DEMAND_TEMPLATE } from "@/graphql/demandTemplates/mutations/deleteDemandTemplate";
import { UPDATE_DEMAND_TEMPLATE } from "@/graphql/demandTemplates/mutations/updateDemandTemplate";
// recurrence pattern
import { UPDATE_RECURRENCE_PATTERN } from "@/graphql/demandTemplates/mutations/updateRecurrencePattern";
// demand parent
import { CREATE_DEMAND_PARENT } from "@/graphql/demandTemplates/mutations/createDemandParent";
import { UPDATE_DEMAND_PARENT } from "@/graphql/demandTemplates/mutations/updateDemandParent";
import { DELETE_DEMAND_PARENT } from "@/graphql/demandTemplates/mutations/deleteDemandParent";
// other
import produce from "immer";
import { DemandParentInsertInput, RecurrencePatternInsertInput } from "@/graphql/types";
import { DemandFormInput } from "@/features/demand/useDemand";
import { RecurrencePatternFormInput } from "@/features/recurrencePattern/useRecurrencePattern";

/**
 ***************************
 * Helper functions
 ***************************
 */

// Demand parent input data for grapqhql mutation
export function createDemandParentInput(
  demandList: DemandFormInput[],
  workAreaId: string,
  tenantId: string | undefined
): DemandParentInsertInput[] {
  return demandList.map(demand => {
    const demandParent: DemandParentInsertInput = {
      start_time: demand.startTime,
      end_time: demand.endTime,
      amount: demand.amount,
      work_area_id: workAreaId,
      tenant_id: tenantId
    };
    return demandParent;
  });
}

// Recurrence pattern input data for grapqhql mutation
export function createRecurrencePatternInput(
  recurrencePatternList: RecurrencePatternFormInput[],
  tenantId: string | undefined
): RecurrencePatternInsertInput[] {
  return recurrencePatternList.map(pattern => {
    const recurrencePattern: RecurrencePatternInsertInput = {
      byweekday: pattern.byweekday,
      freq: pattern.freq,
      interval: pattern.interval,
      bymonth: pattern.bymonth,
      bysetpos: pattern.bysetpos,
      dtstart: pattern.dtstart,
      bydates: pattern.bydates,
      tenant_id: tenantId
    };
    return recurrencePattern;
  });
}

/**
 ***************************
 * Store hook
 ***************************
 */

export default function useDemandTemplateStore(options?: {
  demandTemplateId?: string;
  demandTemplateIds?: string[];
  workAreaId?: string;
  scheduleId?: string;
  allDemandTemplates?: boolean;
}) {
  const { tenantId } = useAuth();

  /**
   * Query all demandTemplates
   * Either by list of ids or by work area id
   */

  const demandTemplatesQueryVariables = computed(() => {
    return {
      tenantId: tenantId.value,
      workAreaId: options?.workAreaId,
      scheduleId: options?.scheduleId,
      // only include templates that have no related schedule
      generalTemplate: options?.scheduleId ? false : true,
      ids: options?.demandTemplateIds
    };
  });

  const { result: demandTemplatesResult, loading: demandTemplatesLoading, error: demandTemplatesError } = useQuery<
    DemandTemplatesQuery
  >(DEMAND_TEMPLATES, demandTemplatesQueryVariables.value, () => ({
    enabled:
      options?.allDemandTemplates === true &&
      (options?.workAreaId != undefined || options?.demandTemplateIds != undefined)
  }));
  const demandTemplates = useResult(demandTemplatesResult, [], data => data.demand_template);

  /**
   * Query single demand template
   */

  // query variables
  const demandTemplateQueryVariables = computed(() => {
    return { tenantId: tenantId.value, demandTemplateId: options?.demandTemplateId };
  });
  // get demandTemplate
  const {
    result: demandTemplateResult,
    loading: demandTemplateLoading,
    error: demandTemplateError,
    onResult: onDemandTemplateResult
  } = useQuery<DemandTemplateQuery>(DEMAND_TEMPLATE, demandTemplateQueryVariables.value, () => ({
    enabled: options?.demandTemplateId != undefined
  }));
  const demandTemplate = useResult(demandTemplateResult, null, data => data.demand_template[0]);

  /**
   * Create demand template
   */

  const {
    mutate: createDemandTemplate,
    loading: createDemandTemplateLoading,
    error: createDemandTemplateError,
    onDone: onCreateDemandTemplateSuccess
  } = useMutation(CREATE_DEMAND_TEMPLATE, {
    update: (cache, { data: { insert_demand_template_one } }) => {
      // read demandTemplates data from cache
      // DON'T FORGET VARIABLES
      const demandTemplatesData = cache.readQuery<DemandTemplatesQuery>({
        query: DEMAND_TEMPLATES,
        variables: demandTemplatesQueryVariables.value
      });

      // only update cache if query is already stored
      if (demandTemplatesData) {
        // update data if query is already in cache
        // add demandTemplate to users list
        const demandTemplatesUpdate = produce(demandTemplatesData?.demand_template, draftState => {
          // add demandTemplate to state
          draftState?.push(insert_demand_template_one);
        });

        // write data back to cache
        // DON'T FORGET VARIABLES
        cache.writeQuery({
          query: DEMAND_TEMPLATES,
          variables: demandTemplatesQueryVariables.value,
          data: { demand_template: demandTemplatesUpdate }
        });
      }
    }
  });

  const onCreateDemandTemplate = (
    name: string,
    description: string,
    demandList: DemandFormInput[],
    recurrencePatternList: RecurrencePatternFormInput[]
  ) => {
    createDemandTemplate({
      name: name,
      description: description,
      demandParentInput: createDemandParentInput(
        demandList,
        options?.workAreaId ? options.workAreaId : "",
        tenantId.value
      ),
      recurrencePatternInput: createRecurrencePatternInput(recurrencePatternList, tenantId.value),
      workAreaId: options?.workAreaId,
      tenantId: tenantId.value
    });
  };

  /**
   * Delete demand template
   */

  const {
    mutate: deleteDemandTemplate,
    loading: deleteDemandTemplateLoading,
    error: deleteDemandTemplateError,
    onDone: onDeleteDemandTemplateSuccess
  } = useMutation(DELETE_DEMAND_TEMPLATE, {
    update: cache => {
      // read demandTemplates data from cache
      // DON'T FORGET VARIABLES
      const demandTemplatesData = cache.readQuery<DemandTemplatesQuery>({
        query: DEMAND_TEMPLATES,
        variables: demandTemplatesQueryVariables.value
      });

      // remove demandTemplate to demand_template list
      const demandTemplatesUpdate = produce(demandTemplatesData?.demand_template, draft => {
        // add demandTemplate to state
        return draft?.filter(user => user.id !== options?.demandTemplateId);
      });

      // write data back to cache
      // DON'T FORGET VARIABLES
      cache.writeQuery({
        query: DEMAND_TEMPLATES,
        variables: demandTemplatesQueryVariables.value,
        data: { demand_template: demandTemplatesUpdate }
      });
    }
  });

  const onDeleteDemandTemplate = (demandTemplateId: string) =>
    deleteDemandTemplate({ tenantId: tenantId.value, demandTemplateId: demandTemplateId });

  /**
   * Update demandTemplate
   */

  const {
    mutate: updateDemandTemplate,
    loading: updateDemandTemplateLoading,
    error: updateDemandTemplateError,
    onDone: onUpdateDemandTemplateSuccess
  } = useMutation(UPDATE_DEMAND_TEMPLATE);

  const onUpdateDemandTemplate = (name: string, description: string) => {
    updateDemandTemplate({
      name: name,
      description: description,
      demandTemplateId: options?.demandTemplateId,
      tenantId: tenantId.value
    });
  };

  /**
   ****************************** Recurrence Pattern ******************************
   */

  // get recurrence patterns
  const recurrencePatterns = computed(() => demandTemplate.value?.recurrence_patterns);

  /**
   * Update recurrence pattern
   */

  const {
    mutate: updateRecurrencePattern,
    loading: updateRecurrencePatternLoading,
    error: updateRecurrencePatternError,
    onDone: onUpdateRecurrencePatternSuccess
  } = useMutation(UPDATE_RECURRENCE_PATTERN);

  const onUpdateRecurrencePattern = (recurrencePatternId: string, byweekday: number[]) => {
    updateRecurrencePattern({
      recurrencePatternId: recurrencePatternId,
      byweekday: byweekday,
      tenantId: tenantId.value
    } as RecurrencePatternInsertInput);
  };

  /**
   ****************************** Demand Parents ******************************
   */

  // get demand parents
  const demandParents = computed(() => demandTemplate.value?.demand_parents);

  /**
   * Create demand parent
   */

  const {
    mutate: createDemandParent,
    loading: createDemandParentLoading,
    error: createDemandParentError,
    onDone: onCreateDemandParentSuccess
  } = useMutation(CREATE_DEMAND_PARENT, {
    update: (cache, { data: { insert_demand_parent_one } }) => {
      // read employees data from cache
      // DON'T FORGET VARIABLES
      const demandTemplateData = cache.readQuery<DemandTemplateQuery>({
        query: DEMAND_TEMPLATE,
        variables: demandTemplateQueryVariables.value
      });

      // only update cache if query is already stored
      if (demandTemplateData) {
        // add demand parent
        const demantTemplateUpdate = produce(demandTemplateData?.demand_template, draft => {
          // get demand template
          const template = draft?.find(template => template.id === demandTemplate.value?.id);
          if (template) {
            // add demand parent
            template.demand_parents.push(insert_demand_parent_one);
          }
        });

        // write data back to cache
        // DON'T FORGET VARIABLES
        cache.writeQuery({
          query: DEMAND_TEMPLATE,
          variables: demandTemplateQueryVariables.value,
          data: { demand_template: demantTemplateUpdate }
        });
      }
    }
  });

  const onCreateDemandParent = (startTime: string, endTime: string, amount: number) => {
    createDemandParent({
      startTime: startTime,
      endTime: endTime,
      amount: amount,
      workAreaId: options?.workAreaId,
      demandTemplateId: options?.demandTemplateId,
      tenantId: tenantId.value
    });
  };

  /**
   * Delete demand parent
   */

  const {
    mutate: deleteDemandParent,
    loading: deleteDemandParentLoading,
    error: deleteDemandParentError,
    onDone: onDeleteDemandParentSuccess
  } = useMutation(DELETE_DEMAND_PARENT, {
    update: (cache, { data: { delete_demand_parent } }) => {
      // read employees data from cache
      // DON'T FORGET VARIABLES
      const demandTemplateData = cache.readQuery<DemandTemplateQuery>({
        query: DEMAND_TEMPLATE,
        variables: demandTemplateQueryVariables.value
      });

      const deletedId = delete_demand_parent.returning[0].id;

      // remove demand parent from parent list
      const demantTemplateUpdate = produce(demandTemplateData?.demand_template, draft => {
        // get demand template
        const template = draft?.find(template => template.id === demandTemplate.value?.id);
        if (template) {
          // find index
          const index = template?.demand_parents.map(x => x.id).indexOf(deletedId);
          // remove selected demand parent
          if (index > -1) {
            template?.demand_parents.splice(index, 1);
          }
        }
      });

      // write data back to cache
      // DON'T FORGET VARIABLES
      cache.writeQuery({
        query: DEMAND_TEMPLATE,
        variables: demandTemplateQueryVariables.value,
        data: { demand_template: demantTemplateUpdate }
      });
    }
  });

  const onDeleteDemandParent = (demandParentId: string | null) => {
    deleteDemandParent({ tenantId: tenantId.value, demandParentId: demandParentId });
  };

  /**
   * Update demand parent
   */

  const {
    mutate: updateDemandParent,
    loading: updateDemandParentLoading,
    error: updateDemandParentError,
    onDone: onUpdateDemandParentSuccess
  } = useMutation(UPDATE_DEMAND_PARENT);

  // Validate and create demandTemplate
  const onUpdateDemandParent = (demand: DemandFormInput) => {
    // execute api call
    updateDemandParent({
      demandParentId: demand.id,
      startTime: demand.startTime,
      endTime: demand.endTime,
      amount: demand.amount,
      tenantId: tenantId.value
    });
  };

  // /**
  //  * Status
  //  */

  const loading = computed(() => {
    if (demandTemplatesLoading.value === true) return true;
    else if (demandTemplateLoading.value === true) return true;
    else if (createDemandTemplateLoading.value === true) return true;
    else if (deleteDemandTemplateLoading.value === true) return true;
    else if (updateDemandTemplateLoading.value === true) return true;
    // recurrence pattern
    else if (updateRecurrencePatternLoading.value === true) return true;
    // demand parent
    else if (createDemandParentLoading.value === true) return true;
    else if (deleteDemandParentLoading.value === true) return true;
    else if (updateDemandParentLoading.value === true) return true;
    else return false;
  });

  const error = computed(() => {
    if (demandTemplatesError.value) return demandTemplatesError.value;
    else if (demandTemplateError.value) return demandTemplateError.value;
    else if (createDemandTemplateError.value) return createDemandTemplateError.value;
    else if (deleteDemandTemplateError.value) return deleteDemandTemplateError.value;
    else if (updateDemandTemplateError.value) return updateDemandTemplateError.value;
    // recurrence pattern
    else if (updateRecurrencePatternError.value) return updateRecurrencePatternError.value;
    // demand parent
    else if (createDemandParentError.value) return createDemandParentError.value;
    else if (deleteDemandParentError.value) return deleteDemandParentError.value;
    else if (updateDemandParentError.value) return updateDemandParentError.value;
    else return null;
  });

  /**
   * Other
   */

  return {
    // error status
    loading,
    error,
    /**
     * demand template
     */
    // queries
    demandTemplates,
    demandTemplate,
    onDemandTemplateResult,
    // create
    onCreateDemandTemplate,
    onCreateDemandTemplateSuccess,
    // delete
    onDeleteDemandTemplate,
    onDeleteDemandTemplateSuccess,
    onUpdateDemandTemplate,
    onUpdateDemandTemplateSuccess,
    /**
     * recurrence patterns
     */
    recurrencePatterns,
    // update
    onUpdateRecurrencePattern,
    onUpdateRecurrencePatternSuccess,
    /**
     * demand parents
     */
    demandParents,
    // create
    onCreateDemandParent,
    onCreateDemandParentSuccess,
    // delete
    onDeleteDemandParent,
    onDeleteDemandParentSuccess,
    // update
    onUpdateDemandParent,
    onUpdateDemandParentSuccess
  };
}
