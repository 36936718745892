import gql from "graphql-tag";

import { demandParentBaseFragment } from "@/graphql/demandTemplates/fragments/demandParentBase";

// update employee
export const UPDATE_DEMAND_PARENT = gql`
  mutation updateDemandParent(
    $demandParentId: uuid!
    $tenantId: uuid!
    $startTime: time!
    $endTime: time!
    $amount: Int!
  ) {
    update_demand_parent(
      where: { id: { _eq: $demandParentId }, tenant_id: { _eq: $tenantId } }
      _set: { start_time: $startTime, end_time: $endTime, amount: $amount }
    ) {
      returning {
        ...DemandParentBase
      }
    }
  }
  ${demandParentBaseFragment.demandParentBase}
`;
