import { reactive } from "vue";
import { v4 as uuidv4 } from "uuid";
import { getWeekdayByNumber } from "@/utils/dateHelpers";
import { RecurrencePattern, RecurrencePatternBaseFragment } from "@/graphql/types";
import { eachDayOfInterval, getDay } from "date-fns";

// object is based on the rrule specification library https://github.com/jakubroztocil/rrule
// changed that that the only array is weekdays. the other arrays (bymonth, bysetpos) were removed
// for more complex rules multiple patterns have to be created

export interface RecurrencePatternFormInput {
  id: string;
  freq: "weekly" | "monthly" | "individual";
  interval: number; // every x weeks/month
  byweekday: number[]; // all weekdays the event occurs, 1=Monday
  bymonth: number | null | undefined; // number of the month 1=Januaray
  bysetpos: number | null | undefined; // index of the day of the month the event occurs => 1 = first Monday of the Month
  dtstart: Date | null | undefined; // start of the recurrence pattern
  bydates: Date[] | null | undefined; // list of all dates the event occurs, will be set individually by the user
}

export interface RecurrencePatternWeekDay {
  id: number;
  selected: boolean;
}

export function createRecurrencePatternFormInputFromRecurrencePattern(
  recurrencePattern: RecurrencePattern | RecurrencePatternBaseFragment
): RecurrencePatternFormInput {
  return {
    id: recurrencePattern.id,
    freq: recurrencePattern.freq as "weekly" | "monthly" | "individual",
    interval: recurrencePattern.interval,
    byweekday: recurrencePattern.byweekday,
    bymonth: recurrencePattern.bymonth,
    bysetpos: recurrencePattern.bysetpos,
    dtstart: recurrencePattern.dtstart,
    bydates: recurrencePattern.bydates
  };
}

export function weeklyRecurrenceToText(pattern: RecurrencePatternBaseFragment) {
  // weekdays
  const weekdays: string[] = pattern.byweekday.map((day: number) => getWeekdayByNumber(day, "short"));
  const weekdaysString = weekdays.join(", ");
  // interval
  const interval = pattern.interval > 1 ? `Every ${pattern.interval}. week` : "Every week";
  // final string
  return `${interval} on ${weekdaysString}`;
}

export function recurrencePatternToText(pattern: RecurrencePatternBaseFragment) {
  // create text based on frequency
  switch (pattern.freq) {
    case "weekly":
      return weeklyRecurrenceToText(pattern);
    default:
      console.error("Only weekly recurrence implemented yet.");
      return "";
  }
}

/**
 * Create a list of dates based on the given recurrence pattern
 * ONLY 'weekly', interval=1 and byweekday
 * @param startDate start date
 * @param endDate end date
 * @param pattern recurrence pattern
 */
export function createDatesFromRecurrencePattern(
  startDate: Date,
  endDate: Date,
  pattern: RecurrencePatternBaseFragment
) {
  // get all days in interval (only start date if dates are equal)
  const interval = eachDayOfInterval({ start: startDate, end: endDate });

  // get only days that are in weekday list
  const intervalByWeekday = interval.filter(date => {
    const day = getDay(date);
    // check if day is 0, if so, set to 7 (getDay starts at 0 for sunday)
    return pattern.byweekday.includes(day === 0 ? 7 : day);
  });

  return intervalByWeekday;
}

/**
 * Hook
 */
export default function useRecurrencePattern() {
  // Initialize recurrence pattern state
  const createRecurrencePatternFormInput = (
    options: {
      id?: string;
      byweekday?: number[];
      freq?: "weekly" | "monthly" | "individual";
      interval?: number;
      bymonth?: number | null;
      bysetpos?: number | null;
      dtstart?: Date | null;
      bydates?: Date[] | null;
    } = {}
  ) => {
    const recurrencePatternFormInput: RecurrencePatternFormInput = {
      id: options.id ? options.id : uuidv4(),
      byweekday: options.byweekday ? options.byweekday : [1, 2, 3, 4, 5],
      freq: options.freq ? options.freq : "weekly",
      interval: options.interval ? options.interval : 1,
      bymonth: options.bymonth ? options.bymonth : null,
      bysetpos: options.bysetpos ? options.bysetpos : null,
      dtstart: options.dtstart ? options.dtstart : null,
      bydates: options.bydates ? options.bydates : null
    };

    return reactive(recurrencePatternFormInput);
  };

  return {
    createRecurrencePatternFormInput
  };
}
