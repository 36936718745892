import gql from "graphql-tag";

// update employee
export const UPDATE_DEMAND_TEMPLATE = gql`
  mutation updateDemandTemplate($demandTemplateId: uuid!, $tenantId: uuid!, $name: String!, $description: String!) {
    update_demand_template(
      where: { id: { _eq: $demandTemplateId }, tenant_id: { _eq: $tenantId } }
      _set: { name: $name, description: $description }
    ) {
      returning {
        id
        name
        description
      }
    }
  }
`;
