import gql from "graphql-tag";

import { recurrencePatternBaseFragment } from "@/graphql/demandTemplates/fragments/recurrencePatternBase";

// update employee
export const UPDATE_RECURRENCE_PATTERN = gql`
  mutation updateRecurrencePattern($recurrencePatternId: uuid!, $tenantId: uuid!, $byweekday: jsonb!) {
    update_recurrence_pattern(
      where: { id: { _eq: $recurrencePatternId }, tenant_id: { _eq: $tenantId } }
      _set: { byweekday: $byweekday }
    ) {
      returning {
        ...RecurrencePatternBase
      }
    }
  }
  ${recurrencePatternBaseFragment.recurrencePatternBase}
`;
