import gql from "graphql-tag";

// delete employee
export const DELETE_DEMAND_TEMPLATE = gql`
  mutation deleteDemandTemplate($demandTemplateId: uuid!, $tenantId: uuid!) {
    delete_demand_template(where: { id: { _eq: $demandTemplateId }, tenant_id: { _eq: $tenantId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
