import gql from "graphql-tag";
import { demandParentBaseFragment } from "./demandParentBase";
import { recurrencePatternBaseFragment } from "./recurrencePatternBase";

export const demandTemplateBaseFragment = {
  demandTemplateBase: gql`
    fragment DemandTemplateBase on demand_template {
      id
      name
      description
      demand_parents(order_by: { start_time: asc }) {
        ...DemandParentBase
      }
      recurrence_patterns {
        ...RecurrencePatternBase
      }
      schedule_id
      work_area_id
    }
    ${recurrencePatternBaseFragment.recurrencePatternBase}
    ${demandParentBaseFragment.demandParentBase}
  `
};
