import gql from "graphql-tag";
import { demandTemplateBaseFragment } from "../fragments/demandTemplateBase";

// create new employee with contract
export const CREATE_DEMAND_TEMPLATE = gql`
  mutation createDemandTemplate(
    $description: String = ""
    $name: String = ""
    $scheduleId: uuid
    $tenantId: uuid!
    $workAreaId: uuid!
    $demandParentInput: [demand_parent_insert_input!]!
    $recurrencePatternInput: [recurrence_pattern_insert_input!]!
  ) {
    insert_demand_template_one(
      object: {
        name: $name
        description: $description
        schedule_id: $scheduleId
        tenant_id: $tenantId
        work_area_id: $workAreaId
        demand_parents: { data: $demandParentInput }
        recurrence_patterns: { data: $recurrencePatternInput }
      }
    ) {
      ...DemandTemplateBase
    }
  }

  ${demandTemplateBaseFragment.demandTemplateBase}
`;
